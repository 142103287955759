import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "./lottie2.json";
import backgroundImage from "../media/back3.png";
import styled from "styled-components";

const ParentContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;

const Container = styled.div`
  padding: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const CircleContainer = styled.div`
  position: absolute;
  filter: blur(50px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const AbsoluteImage = styled.div`
  background-image: url(${backgroundImage}) !important;
  opacity: 0.1;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

class MovingShapes extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <ParentContainer>
        <Container>
          <AbsoluteImage />
          <CircleContainer>
            <Lottie
              options={defaultOptions}
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
            />
          </CircleContainer>
        </Container>
      </ParentContainer>
    );
  }
}

export default MovingShapes;
