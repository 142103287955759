import { forwardRef } from "react";

interface VideoProps {
  videoSrc: string;
  autoPlay?: boolean;
  className?: string;
  controls?: boolean;
  muted?: boolean;
  onClick?: () => void;
}

export const Video = forwardRef<HTMLVideoElement, VideoProps>(
  (
    {
      videoSrc,
      autoPlay = false,
      className,
      controls = false,
      muted = true,
      onClick,
    },
    ref
  ) => {
    return (
      <video
        autoPlay={autoPlay}
        className={className}
        loop={true}
        muted={muted}
        controls={controls}
        ref={ref}
        onClick={onClick}
        playsInline={autoPlay}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    );
  }
);
