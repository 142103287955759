import styled from "styled-components";
import { Link } from "react-router-dom";

export const TabLogoContainer = styled.div`
  height: 2.5rem;
  margin-right: 1.25rem;
  margin-left: 1.25rem;

  @media screen and (max-width: 768px) {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
`;

export const TabText = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-family: var(--rustica);
  font-weight: lighter;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-right: 8px;
  }
`;

export const TabContent = styled.div`
  width: 60rem;
  height: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .video,
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 16/9;
    margin-top: 6rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100vw;
    height: auto;

    .video,
    .image {
      margin-top: 1rem;
    }
  }
`;

export const TabButtonContainer = styled.div`
  margin: 2rem auto;
  display: flex;
  width: fit-content;
`;

export const TabButton = styled(Link)`
  text-align: center;
  font-family: var(--rustica);
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  color: var(--orange);
  transition: 0.3s ease color;

  &:hover {
    color: var(--blue);
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
