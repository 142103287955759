import React, { useEffect, useCallback, useState } from "react";
import MovingShapes from "../components/MovingShapes";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { SoMeContact } from "../sections/Contact/ContactStyles";
import logo from "../media/logo.png";
import {Logo} from '../components/NavigationBar'

interface Responsability {
  key: string;
}

interface Qualification {
  qualification: string;
}

interface Application {
  applicationTitle?: string;
  aboutPosition?: string;
  keyResponsabilities?: Responsability[];
  yourProfile?: string;
  qualifications?: Qualification[];
  positionName?: string;
  positionDuration?: string;
  positionLocation?: string;
  positionStartDate?: string;
  applicationDeadline?: string;
  applicationContactPerson?: string;
  applicationContactEmail?: string;
  applicationContactPhoneNumber?: string;
}

const ApplicationPage: React.FC = () => {
  const { applicationId } = useParams();
  const [application, setApplication] = useState<Application>();

  const showApplication = useCallback(
    (data: any) => {
      if (!applicationId || +applicationId - 1 > data.length) return;
      const app = data.reverse()[+applicationId - 1]?.acf;
      setApplication(app);
    },
    [applicationId]
  );

  useEffect(() => {
    if (!applicationId) return;

    const endpointApplications =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/applications";

    fetch(endpointApplications)
      .then((e) => e.json())
      .then(showApplication);
  }, [applicationId, showApplication]);

  return (
    <>
      <MovingShapes />
      {application?.applicationTitle ?
      
      <Container>
        <Logo src={logo} style={{marginLeft: '0', height: '6rem'}}/>
        <Headline>{application?.applicationTitle}</Headline>
        <Text style={{ marginTop: "3rem" }}>
          <b>Twenty</b> is a hybrid between a strategic consultancy and a
          creative agency. We support our clients all the way from business
          problem through to creative solutions anchored in research and
          community insights.
        </Text>
        <SoMeContact
          href="https://thisistwentyagency.com/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          Learn more about Twenty
        </SoMeContact>

        <SectionHeadline>ABOUT THE POSITION</SectionHeadline>
        <Text>{application?.aboutPosition}</Text>

        <SectionHeadline>KEY RESPONSIBILITIES</SectionHeadline>
        {application?.keyResponsabilities?.map((el, i) => (
          <Text key={i}>&#9679; {el.key}</Text>
        ))}

        <SectionHeadline>YOUR PROFILE</SectionHeadline>
        <Text>{application?.yourProfile}</Text>

        <SectionHeadline>QUALIFICATIONS</SectionHeadline>
        {application?.qualifications?.map((el, i) => (
          <Text key={i}>&#9679; {el.qualification}</Text>
        ))}

        <Footer>
          <div>
            <Title>ROLE</Title>

            <Flex>
              <Text style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Position:
              </Text>
              <Text>{application?.applicationTitle}</Text>
            </Flex>

            <Flex>
              <Text
                style={{
                  fontWeight: "bold",
                  marginRight: ".5rem",
                  marginTop: ".5rem",
                }}
              >
                Duration:
              </Text>
              <Text style={{ marginTop: ".5rem" }}>
                {application?.positionDuration}
              </Text>
            </Flex>

            <Flex>
              <Text
                style={{
                  fontWeight: "bold",
                  marginRight: ".5rem",
                  marginTop: ".5rem",
                }}
              >
                Start:
              </Text>
              <Text style={{ marginTop: ".5rem" }}>
                {application?.positionStartDate}
              </Text>
            </Flex>

            <Flex>
              <Text
                style={{
                  fontWeight: "bold",
                  marginRight: ".5rem",
                  marginTop: ".5rem",
                }}
              >
                Location:
              </Text>
              <Text style={{ marginTop: ".5rem" }}>
                {application?.positionLocation}
              </Text>
            </Flex>

            <Flex>
              <Text style={{ marginRight: ".5rem", marginTop: ".5rem" }}>
                Application deadline:
              </Text>
              <Text style={{ marginTop: ".5rem", fontWeight: "bold" }}>
                {application?.applicationDeadline}
              </Text>
            </Flex>
          </div>
          <div>
            <Title>APPLICATION</Title>

            <Text>
              If you see yourself in this position and would like to join the
              TWENTY team, please send your application, CV, and other relevant
              information to:
            </Text>

            <Flex>
              <Text
                style={{
                  fontWeight: "bold",
                  marginRight: ".5rem",
                  marginTop: ".5rem",
                }}
              >
                Contact:
              </Text>
              <Text style={{ marginTop: ".5rem" }}>
                {application?.applicationContactPerson}
              </Text>
            </Flex>

            <Flex>
              <Text
                style={{
                  fontWeight: "bold",
                  marginRight: ".5rem",
                  marginTop: ".5rem",
                }}
              >
                Email:
              </Text>
              <Text style={{ marginTop: ".5rem" }}>
                {application?.applicationContactEmail}
              </Text>
            </Flex>

            <Flex>
              <Text
                style={{
                  fontWeight: "bold",
                  marginRight: ".5rem",
                  marginTop: ".5rem",
                }}
              >
                Phone:
              </Text>
              <Text style={{ marginTop: ".5rem" }}>
                {application?.applicationContactPhoneNumber}
              </Text>
            </Flex>
          </div>
        </Footer>
        <Text style={{ marginTop: "2rem" }}>
          Interviews will be conducted on a rolling basis. WE LOOK FORWARD TO
          HEARING FROM YOU!
        </Text>
      </Container>
      : <Container>
        <Headline>Uppssss...</Headline>
        <Title style={{marginTop: '2rem'}}>Looks like the application has been removed.</Title>
        <Title style={{marginTop: '1rem'}}>Go to our <SoMeContact
          href="https://thisistwentyagency.com/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "blue", textDecoration: "underline", fontSize: '18px' }}
        >
          homepage
        </SoMeContact> and send us an email anyway.</Title>
        
      </Container>  
    }
    </>
  );
};

export default ApplicationPage;

const Container = styled.div`
  position: relative;
  width: 800px;
  padding: 6rem 0;
  margin: auto;

  @media screen and (max-width: 800px) {
    width: auto;
    padding: 6rem .5rem;
  }
`;

const Headline = styled.h1`
  font-family: var(--shippori);
  font-size: 38px;
  max-width: 600px;
`;

const SectionHeadline = styled.h3`
  font-family: var(--shippori);
  font-size: 24px;
  margin-top: 2.5rem;
`;

const Text = styled.p`
  font-family: var(--rustica);
  font-size: 14px;
  font-weight: 100;
  line-height: 1.6;
  letter-spacing: 1px;
  margin-top: 1rem;
`;

const Footer = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;;
  }
`;

const Flex = styled.div`
  display: flex;

`;

const Title = styled.p`
  font-family: var(--rustica);
  font-weight: bold;
  font-size: 18px;
`;
