import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Home } from "../sections/Home/Home";
import { AboutUs, AboutUsProps } from "../sections/AboutUs/AboutUs";
import { OurClients, OurClientsProps } from "../sections/OurClients/OurClients";
import { OurTeam, OurTeamProps } from "../sections/OurTeam/OurTeam";
import { Contact } from "../sections/Contact/Contact";

import MovingShapes from "../components/MovingShapes";
import NavigationBar from "../components/NavigationBar";

const HomePage: React.FC = () => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [aboutUs, setAboutUs] = useState<AboutUsProps>();
  const [ourClients, setOurClients] = useState<OurClientsProps>();
  const [ourTeam, setOurTeam] = useState<OurTeamProps>();
  const [officeAddress, setOfficeAddress] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);

    const endpointAboutUs =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/about_us";
    const endpointOurClients =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/our_clients?per_page=100";
    const endpointOurTeam =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/our_team?per_page=100";
    const endpointContactus =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/contact_us";

    fetch(endpointAboutUs)
      .then((e) => e.json())
      .then(showAboutUs);
    fetch(endpointOurClients)
      .then((e) => e.json())
      .then(showOurClients);
    fetch(endpointOurTeam)
      .then((e) => e.json())
      .then(showOurTeam);
    fetch(endpointContactus)
      .then((e) => e.json())
      .then(showContactUs);
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (ourClients?.clients.length && hash.length) {
      const aboutUsHTML = document.getElementById("about-us");
      const ourWorkHTML = document.getElementById("our-work");
      const ourTeamHTML = document.getElementById("our-team");
      const contactUsHTML = document.getElementById("contact-us");

      if (aboutUsHTML?.id && hash.includes(aboutUsHTML.id))
        return aboutUsHTML.scrollIntoView({ behavior: "smooth" });
      if (ourWorkHTML?.id && hash.includes(ourWorkHTML.id))
        return ourWorkHTML.scrollIntoView({ behavior: "smooth" });
      if (ourTeamHTML?.id && hash.includes(ourTeamHTML.id))
        return ourTeamHTML.scrollIntoView({ behavior: "smooth" });
      if (contactUsHTML?.id && hash.includes(contactUsHTML.id))
        return contactUsHTML.scrollIntoView({ behavior: "smooth" });
    }
  }, [ourClients]);

  const getDrawerVisibility = (e: boolean) => setVisibleDrawer(e);

  const showAboutUs = (data: any) => {
    return setAboutUs(data[0].acf);
  };

  const showOurClients = (data: any) => {
    const arrMap: any = [];
    data.map((el: any) => {
      return arrMap.push({
        name: el.acf.name,
        videoSrc: el.acf.videoSrc,
        imgSrc: el.acf.imgSrc,
        link: el.acf.clientId,
      });
    });
    return setOurClients({ clients: arrMap.reverse() });
  };

  const showOurTeam = (data: any) => {
    return setOurTeam({
      description1stParagraph: data[0].acf.headline,
      members: data[0].acf.team_members,
    });
  };

  const showContactUs = (data: any) => {
    return setOfficeAddress(data[0].acf.office_address);
  };

  return (
    <>
      <MovingShapes />
      {(aboutUs?.headline || ourTeam?.description1stParagraph) && (
        <Layout>
          <NavigationBar isVisible={(e) => getDrawerVisibility(e)} />
          {!visibleDrawer && (
            <>
              <Home videoSrc="reel.mp4" />
              {aboutUs?.headline && (
                <AboutUs
                  headline={aboutUs.headline}
                  principles={aboutUs.principles}
                />
              )}
              {ourClients && <OurClients clients={ourClients.clients} />}
              {ourTeam?.description1stParagraph && (
                <OurTeam
                  description1stParagraph={ourTeam.description1stParagraph}
                  members={ourTeam.members}
                />
              )}
              <Contact arrowUpId="#top" contactAddress={officeAddress} />
            </>
          )}
        </Layout>
      )}
    </>
  );
};

export default HomePage;
