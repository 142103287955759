import styled from "styled-components";

export const Paragraph = styled.p`
  font-family: var(--rustica);
  font-size: 10px;
  line-height: 2;
  letter-spacing: 1px;
  max-width: 420px;
  padding-top: 20px;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }
`;

export const ParagraphTitle = styled.p`
  font-family: var(--rustica);
  font-size: 12px;
  line-height: 2;
  letter-spacing: 1px;
  max-width: 420px;
  padding-top: 20px;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  padding: 5rem 3rem 0;
  justify-content: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding: 2rem 1rem 0;
    grid-gap: 1rem;
  }
`;

export const CardMember = styled.div`
  width: 100%;
  height: 30rem;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const Name = styled.h3`
  font-family: var(--shippori);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const Picture = styled.img`
  padding-top: 1rem;
  max-width: 100%;
  max-height: 80%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
`;
