import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1240px) {
    grid-template-columns: 1fr;
  }
`;

export const Headline = styled.h1`
  font-family: var(--shippori);
  font-size: 46px;
  text-transform: uppercase;
  padding: 6rem 0 0 13rem;
  width: 20rem;
  line-height: 1.2;
  letter-spacing: 3px;
  width: 35rem;
  font-weight: 500;

  @media screen and (max-width: 1240px) {
    padding: 4rem 1rem 2rem 4rem;
    width: 45%;
  }

  @media screen and (max-width: 768px) {
    padding: 4rem 1rem 2rem 2rem;
    width: fit-content;
    font-size: 32px;
  }
`;

export const Principles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24rem 0 0 5rem;

  @media screen and (max-width: 1240px) {
    padding: 2rem 2rem 6rem 45%;
  }
  @media screen and (max-width: 768px) {
    padding: 0 2rem 6rem;
  }
`;

export const PrincipleItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
`;

export const PrincipleTitle = styled.p`
  font-family: var(--shippori);
  font-size: 14px;
  font-weight: bold;
  width: 5rem;
  margin-right: 64px;
  @media screen and (max-width: 768px) {
    margin-right: 30px;
  }
`;

export const PrincipleText = styled.p`
  font-family: var(--rustica);
  font-size: 12px;
  font-weight: lighter;
  line-height: 2;
  letter-spacing: 1px;
  width: 320px;

  @media screen and (max-width: 768px) {
    width: 60%;
  }
`;

export const ArrowDownContainer = styled.div`
  position: absolute;
  top: 70vh;
  left: 3rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
