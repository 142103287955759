import React from "react";
import styled from "styled-components";

const Line = styled.div`
  width: 3rem;
  border-bottom: 3px solid var(--orange);
  position: absolute;
  right: 0;
  bottom: 12px;
`;

export const SectionHeadline = styled.h2`
  position: relative;
  height: auto;
  font-family: var(--shippori);
  font-size: 40px;
  font-weight: bold;
  display: flex;
  width: 22rem;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const Anchor = styled.a`
  position: relative;
  height: auto;
  font-family: var(--shippori);
  color: var(--black);
  font-size: 40px;
  font-weight: bold;
  display: flex;
  width: 17rem;
  text-decoration: none;
  transition: 0.3s ease color;

  &:hover {
    color: var(--blue);
  }
`;

interface SectionNameProps {
  name: string;
  showLine?: boolean;
  href?: string;
}

export const SectionName = ({
  name,
  showLine,
  href,
}: SectionNameProps): JSX.Element => {
  if (href)
    return (
      <Anchor href={href}>
        {name}
        {showLine && <Line />}
      </Anchor>
    );
  return (
    <SectionHeadline>
      {name}
      {showLine && <Line />}
    </SectionHeadline>
  );
};
