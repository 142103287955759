import styled from "styled-components";

export const HomeLayout = styled.div`
  width: 100vw;
  height: calc(100vh - 120px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1920px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;
