import React, { useEffect, useRef, useState } from "react";
import { Video } from "../../components/Video";
import { ArrowDownButton } from "../../components/ArrowDownButton";
import { SoMeButton } from "../../components/SoMeButton";
import "./Home.css";
import { HomeLayout } from "./HomeStyles";

interface HomeProps {
  videoSrc: string;
}

export const Home: React.FC<HomeProps> = ({ videoSrc }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mouseRef = useRef<HTMLDivElement>(null);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playStopReel, setPlayStopReel] = useState<boolean>(false);

  const playPauseVideo = () => {
    setPlayStopReel((s) => !s);
    if (!videoRef.current) return;
    const videoCurrent = videoRef.current;
    if (window.innerWidth > 1240) {
      videoCurrent.style.width = "70%";
      videoCurrent.style.transition = ".4s ease width";
    }

    if (!isPlaying) {
      setIsPlaying(true);
      videoCurrent.muted = false;
      videoCurrent.defaultMuted = true;
      videoCurrent.currentTime = 0;
      videoCurrent.load();
      return videoCurrent.play();
    }
  };

  const handleMouseMove = (e: any) => {
    setMouseOver(true);
    const mouseCircle = mouseRef.current;

    let mousePosX = e.pageX;
    let mousePosY = e.pageY;

    let revisedMousePosX = 0;
    let revisedMousePosY = 0;

    revisedMousePosX += mousePosX + revisedMousePosX;
    revisedMousePosY += mousePosY + revisedMousePosY;

    mouseCircle && (mouseCircle.style.left = revisedMousePosX + "px");
    mouseCircle && (mouseCircle.style.top = revisedMousePosY - 126 + "px");
  };

  const handleMouseOut = () => {
    setMouseOver(false);
  };

  const stopVideo: IntersectionObserverCallback = (entries) => {
    if (!videoRef.current) return;
    if (!entries[0].isIntersecting) {
      setPlayStopReel(false);
      const videoCurrent = videoRef.current;
      if (window.innerWidth < 768) {
        videoCurrent.muted = true;
        videoCurrent.defaultMuted = true;
        videoCurrent.currentTime = 0;
        return setIsPlaying(false);
      }

      if (window.innerWidth > 1240) {
        videoCurrent.style.width = "45%";
        videoCurrent.style.transition = ".4s ease width";
      }
      videoCurrent.muted = true;
      videoCurrent.defaultMuted = true;
      videoCurrent.currentTime = 0;
      videoCurrent.load();
      videoCurrent.play();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const observer = new IntersectionObserver(stopVideo, {
      root: null,
      threshold: 0.5,
    });

    const videoCurrent = videoRef.current;
    videoCurrent.style.backgroundColor = "#0000";
    videoCurrent && observer.observe(videoCurrent);

    videoCurrent.addEventListener("mousemove", handleMouseMove);
    videoCurrent.addEventListener("mouseout", handleMouseOut);

    return () => {
      videoCurrent && observer.unobserve(videoCurrent);
      videoCurrent.removeEventListener("mousemove", handleMouseMove);
      videoCurrent.removeEventListener("mouseout", handleMouseOut);
    };
  }, [videoRef]);

  return (
    <HomeLayout id="home">
      <div
        ref={mouseRef}
        className={
          mouseOver && window.innerWidth > 1360 ? "mouseCursor" : "mouseHidden"
        }
      >
        {playStopReel ? "PAUSE REEL" : "PLAY REEL"}
      </div>

      <Video
        className="videoHome relative"
        ref={videoRef}
        videoSrc={videoSrc}
        autoPlay={!isPlaying}
        controls={isPlaying}
        onClick={playPauseVideo}
      />

      <div className="absoluteArrowBottom">
        <ArrowDownButton href="#about-us" />
      </div>

      <div className="absoluteSoMeHome">
        <SoMeButton
          title="Instagram"
          href="https://www.instagram.com/thisistwentyagency/"
          className="soMeButtonsHome"
        />
        <SoMeButton
          title="LinkedIn"
          href="https://www.linkedin.com/company/thisistwentyagency/"
          className="soMeButtonsHome"
        />
        <SoMeButton
          title="Facebook"
          href="https://www.facebook.com/thisistwentyagency"
          className="soMeButtonsHome"
        />
      </div>

      <div className="absoluteContactUs">
        <SoMeButton
          title="Contact &nbsp; &#8212; &nbsp; Us"
          href="mailto:thomas@thisistwentyagency.com"
        />
      </div>
    </HomeLayout>
  );
};
