import { ArrowDown } from "../assets/icons/icons";
import styled from "styled-components";

interface ArrowDownButtonProps {
  href?: string;
  arrowUp?: boolean;
  onClick?: () => void;
}

export const ArrowDownButton: React.FC<ArrowDownButtonProps> = ({
  href,
  arrowUp,
  onClick,
}) => {
  const ArrowDownStyled = styled.div`
    margin: auto auto 1rem auto;
    bottom: ${() => (arrowUp ? "45px" : "30px")};
    text-align: center;
    transform: ${() => (arrowUp ? "rotate(180deg)" : "")};
    border: 2px solid blue;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: ${() => (arrowUp ? "2rem" : "2.5rem")};
      height: ${() => (arrowUp ? "2rem" : "2.5rem")};
    }
  `;

  return (
    <a
      href={href}
      style={{ cursor: "pointer", WebkitTapHighlightColor: "transparent" }}
    >
      <span onClick={onClick}>
        <ArrowDownStyled>
          <ArrowDown style={{ height: "18px" }} />
        </ArrowDownStyled>
      </span>
    </a>
  );
};
