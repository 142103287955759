import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
  border-top: 1px solid var(--blue);

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Column = styled.div`
  margin-top: 2rem;

  &:nth-child(2) {
    padding-left: 4rem;
  }

  &:nth-child(3) {
    padding-left: 8rem;
  }

  @media screen and (max-width: 768px) {
    &:first-of-type {
      width: 245px;
    }
    &:nth-child(2) {
      padding: 0;
    }

    &:nth-child(3) {
      display: none;
    }
  }
`;

export const Title = styled.h3`
  font-family: var(--shippori);
  font-size: 20px;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ContainerContactUs = styled.div`
  margin-top: 13rem;
  margin-bottom: 2rem;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: 8rem;
  }
`;

export const SectionName = styled.a`
  position: relative;
  height: auto;
  font-family: var(--shippori);
  color: var(--black);
  font-size: 40px;
  font-weight: bold;
  display: flex;
  width: 17rem;
  text-decoration: none;
  transition: 0.3s ease color;

  &:hover {
    color: var(--blue);
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Line = styled.div`
  width: 2rem;
  border-bottom: 3px solid var(--orange);
  position: absolute;
  right: -10px;
  bottom: 22px;

  @media screen and (max-width: 768px) {
    left: 125px;
    bottom: 10px;
    width: 1rem;
    border-bottom: 2px solid var(--orange);
  }
`;

export const RedParagraph = styled.p`
  font-family: var(--rustica);
  color: var(--red);
  font-size: 12px;
  line-height: 2;
  letter-spacing: 1px;
  max-width: 420px;
`;

export const ArrowUpContainer = styled.div`
  position: absolute;
  bottom: 10rem;
  left: 3rem;

  @media screen and (max-width: 768px) {
    bottom: 5rem;
    left: 1rem;
  }
`;

export const SoMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const SoMeContact = styled.a`
  text-decoration: none;
  color: var(--black);
  font-family: var(--rustica);
  font-size: 12px;
  line-height: 2;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.3s ease color;
  width: 64px;

  &:hover {
    color: var(--blue);
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContactNav = styled.a`
  white-space: nowrap;
  margin-bottom: 12px;
  font-family: var(--shippori);
  font-size: 20px;
  text-decoration: none;
  color: var(--black);
  transition: 0.3s ease color;
  width: 120px;
  cursor: pointer;
  position: relative;
  padding-left: 24px;

  &:hover {
    color: var(--blue);
  }
`;

export const SmallNavNo = styled.span`
  font-family: var(--rustica);
  font-size: 8px;
  line-height: 2;
  letter-spacing: 1px;
  margin-right: 12px;
  position: absolute;
  left: 0;
  top: 8px;
`;
