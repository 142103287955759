import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import {
  TextContainer,
  CaseVideo,
  Headline,
  Rustica,
  Shiporri,
  Grid,
  Title,
  Text,
  Mockup,
  PresentationImage,
  AwardsContainer,
  Logo,
  ContainerAward,
  ContainerVideo,
  ContainerButtons,
  CaseButton,
  Left,
  Right,
  CampaignImageContainer,
  CampaignLeft,
  CampaignRight,
  MockupContainer,
  MockupImageIndicator,
  CampaignResults,
  CampaignResult,
  PublicationContainer,
  ContainerPublication,
  CaseVideoContainer,
  ClientsNavigation,
  CaseImage,
  IFrameContainer,
  CampaingResultsContainer,
  IphoneImageContainer,
} from "./CaseDescriptionStyles";
import { Link } from "react-router-dom";

const { Content } = Layout;

interface Award {
  logo: string;
}
interface Publication {
  logo: string;
  text: string;
}

interface Campaign {
  title: string;
  text: string;
}

interface Client {
  id: string;
  name: string;
}

interface CampaignImages {
  imgSrc?: string;
  videoSrc?: string;
}

export interface Case {
  videoSrc?: string;
  imgSrc?: string;
  title: string;
  clientName: string;
  year: string;
  brief: string;
  insight: string;
  creativeIdea: string;
  campaignImages?: CampaignImages[];
  presentationImage1?: string;
  presentationImage2?: string;
  presentationImage3?: string;
  campaignResults?: Campaign[];
  awards?: Award[];
  publications?: Publication[];
}

export interface CaseDescriptionProps {
  cases: Case[];
  clients: Client[];
  clientId?: string;
}

const CaseDescription: React.FC<CaseDescriptionProps> = ({
  cases,
  clients,
  clientId,
}) => {
  const [caseNumber, setCaseNumber] = useState<number>(0);
  const [campaignImage, setCampaignImage] = useState<number>(0);
  const [campaignImagesArray, setCampaignImagesArray] = useState<string[]>();

  useEffect(() => {
    const imgsArr: any = [];
    if ((cases[caseNumber]?.campaignImages?.length as number) > 0) {
      cases[caseNumber].campaignImages?.map((img) => {
        if (img.imgSrc) return imgsArr.push(img.imgSrc);
        if (img.videoSrc) return imgsArr.push(img.videoSrc);
        return imgsArr;
      });
    }
    setCampaignImagesArray(imgsArr);
  }, [cases, caseNumber]);

  const campaignLeftClicked = () => {
    if (campaignImage === 0) {
      return setCampaignImage(
        () => (campaignImagesArray?.length as number) - 1
      );
    }

    setCampaignImage((s) => s - 1);
  };

  const campaignRightClicked = () => {
    if (campaignImage === (campaignImagesArray?.length as number) - 1)
      return setCampaignImage(0);
    setCampaignImage((s) => s + 1);
  };
  return (
    <Layout id="case-description" className="layout column">
      <ContainerVideo>
        <CaseVideoContainer>
          <ClientsNavigation>
            {clients.map((client, clientIndex) => (
              <Link
                to={`/${client.id}`}
                replace
                key={clientIndex}
                style={{ textDecoration: "none" }}
              >
                <CaseButton
                  onClick={() => setCaseNumber(0)}
                  style={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    color: clientId === client.id ? "#FF7900" : "#1F1FF5",
                  }}
                >
                  {client.name}
                </CaseButton>
              </Link>
            ))}
          </ClientsNavigation>

          <CaseVideo
            style={{
              marginTop:
                window.innerWidth < 960 && clients.length > 6 ? "2.3rem" : "",
            }}
          >
            {cases[caseNumber]?.videoSrc && !cases[caseNumber]?.imgSrc && (
              <iframe
                src={cases[caseNumber]?.videoSrc}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                title={cases[caseNumber]?.clientName}
              ></iframe>
            )}
            {cases[caseNumber]?.imgSrc && (
              <CaseImage
                src={cases[caseNumber]?.imgSrc}
                alt={cases[caseNumber]?.clientName}
              />
            )}
          </CaseVideo>
        </CaseVideoContainer>
        <ContainerButtons
          style={{
            justifyContent: "center",
          }}
        >
          {cases.length && cases.length > 1 && caseNumber !== 0 && (
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                marginRight: "1rem",
              }}
              onClick={() => {
                setCaseNumber((s) => s - 1);
                setCampaignImage(0);
              }}
            >
              <Left />
              <CaseButton>back</CaseButton>
            </div>
          )}
          {cases.length &&
            cases.length > 1 &&
            cases.length > caseNumber + 1 && (
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  marginLeft: "1rem",
                }}
                onClick={() => {
                  setCaseNumber((s) => s + 1);
                  setCampaignImage(0);
                }}
              >
                <CaseButton>next</CaseButton>
                <Right />
              </div>
            )}
        </ContainerButtons>
      </ContainerVideo>

      <Content className="content contentSection">
        <Headline>{cases[caseNumber]?.title}</Headline>

        <TextContainer>
          <Shiporri>Client:</Shiporri>
          <Rustica>{cases[caseNumber]?.clientName}</Rustica>
        </TextContainer>
        <TextContainer>
          <Shiporri>Year:</Shiporri>
          <Rustica>{cases[caseNumber]?.year}</Rustica>
        </TextContainer>
        <Grid>
          <div>
            <Title>Brief</Title>
            <Text>{cases[caseNumber]?.brief}</Text>
          </div>
          <div>
            <Title>Insight</Title>
            <Text>{cases[caseNumber]?.insight}</Text>
          </div>
          <div>
            <Title>Creative idea</Title>
            <Text>{cases[caseNumber]?.creativeIdea}</Text>
          </div>
        </Grid>

        {cases[caseNumber]?.campaignResults && (
          <CampaingResultsContainer>
            <Headline
              style={{
                margin: "auto",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              CAMPAIGN RESULTS
            </Headline>
            <CampaignResults>
              {cases[caseNumber]?.campaignResults?.map((item, itemIndex) => (
                <CampaignResult key={itemIndex}>
                  <Title>{item.title}</Title>
                  <Text
                    style={{
                      width: "6rem",
                      textAlign: "center",
                      lineHeight: "1rem",
                    }}
                  >
                    {item.text}
                  </Text>
                </CampaignResult>
              ))}
            </CampaignResults>
          </CampaingResultsContainer>
        )}
        {campaignImagesArray && (campaignImagesArray?.length as number) > 0 && (
          <CampaignImageContainer>
            <CampaignLeft onClick={campaignLeftClicked} />
            <MockupContainer>
              {campaignImagesArray[campaignImage]?.includes("vimeo") ? (
                <IFrameContainer>
                  <iframe
                    src={campaignImagesArray?.[campaignImage]}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="fullscreen; picture-in-picture"
                    allowFullScreen
                    title={cases[caseNumber]?.clientName}
                  ></iframe>
                </IFrameContainer>
              ) : (
                <Mockup src={campaignImagesArray?.[campaignImage]} />
              )}
              <div style={{ display: "flex", margin: "auto" }}>
                {campaignImagesArray?.map((_, itemIndex) => (
                  <MockupImageIndicator
                    key={itemIndex}
                    style={{
                      borderColor:
                        campaignImage === itemIndex ? "#1F1FF5" : "#FF7900",
                    }}
                  />
                ))}
              </div>
            </MockupContainer>
            <CampaignRight onClick={campaignRightClicked} />
          </CampaignImageContainer>
        )}

        {/* {cases[caseNumber]?.campaignImages?.length &&
          cases[caseNumber]?.campaignImages[0]?.imgSrc && (
            <CampaignImageContainer>
              <CampaignLeft onClick={campaignLeftClicked} />
              <MockupContainer>
                {cases[caseNumber]?.campaignImages?.imgSrc[
                  campaignImage
                ].includes("vimeo") ? (
                  <IFrameContainer>
                    <iframe
                      src={
                        cases[caseNumber]?.campaignImages?.images[campaignImage]
                      }
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allow="fullscreen; picture-in-picture"
                      allowFullScreen
                      title={cases[caseNumber]?.clientName}
                    ></iframe>
                  </IFrameContainer>
                ) : (
                  <Mockup
                    src={cases[caseNumber]?.campaignImages?.images[campaignImage]}
                  />
                )}
                <div style={{ display: "flex", margin: "auto" }}>
                  {cases[caseNumber].campaignImages?.images.map(
                    (_, itemIndex) => (
                      <MockupImageIndicator
                        key={itemIndex}
                        style={{
                          borderColor:
                            campaignImage === itemIndex ? "#1F1FF5" : "#FF7900",
                        }}
                      />
                    )
                  )}
                </div>
              </MockupContainer>
              <CampaignRight onClick={campaignRightClicked} />
            </CampaignImageContainer>
          )} */}
      </Content>
      {cases[caseNumber]?.presentationImage1 && (
        <IphoneImageContainer src={cases[caseNumber].presentationImage1} />
      )}
      {cases[caseNumber]?.presentationImage2 && (
        <PresentationImage src={cases[caseNumber].presentationImage2} />
      )}
      {cases[caseNumber]?.presentationImage3 && (
        <PresentationImage src={cases[caseNumber].presentationImage3} />
      )}

      {cases[caseNumber]?.publications && (
        <PublicationContainer>
          {cases[caseNumber].publications?.map((publication, i) => (
            <ContainerPublication key={i}>
              <Logo src={publication.logo} />

              <Rustica
                style={{
                  marginTop: "1rem",
                  fontWeight: "lighter",
                  fontSize: "12px",
                }}
              >
                "{publication.text}"
              </Rustica>
            </ContainerPublication>
          ))}
        </PublicationContainer>
      )}

      {cases[caseNumber]?.awards && (
        <AwardsContainer>
          {cases[caseNumber].awards?.map((award, i) => (
            <ContainerAward key={i}>
              <Logo src={award.logo} style={{ width: "6rem" }} />
            </ContainerAward>
          ))}
        </AwardsContainer>
      )}
    </Layout>
  );
};

export default CaseDescription;
