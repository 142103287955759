import React, { useState } from "react";
import { Layout } from "antd";
import { SectionHeadline, SectionName } from "../../components/SectionName";
import { Tabs } from "antd";
import {
  TabButton,
  TabContent,
  TabLogoContainer,
  TabButtonContainer,
  TabText,
} from "./OurClientsStyles";

import { ArrowDownContainer } from "../AboutUs/AboutUsStyles";
import { ArrowDownButton } from "../../components/ArrowDownButton";

const { TabPane } = Tabs;
const { Content } = Layout;

interface Client {
  name: string;
  videoSrc?: string;
  imgSrc?: string;
  link?: string;
}
export interface OurClientsProps {
  clients: Client[];
}

export const OurClients: React.FC<OurClientsProps> = ({ clients }) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  return (
    <Layout id="our-work" className="layout layoutOurClients">
      <Content className="content contentSection relative">
        <SectionName name="Our clients" />
        <Tabs
          defaultActiveKey="1"
          style={{ marginTop: "2rem" }}
          tabPosition={"top"}
          onTabClick={(e) => setActiveTab(parseInt(e))}
          className="tabsOurClients"
        >
          {clients.map((client, index) => {
            return (
              <TabPane
                key={index + 1}
                tab={
                  <TabLogoContainer>
                    <TabText
                      style={{
                        color:
                          index + 1 === activeTab
                            ? "var(--orange)"
                            : "var(--blue)",
                      }}
                    >
                      {client.name}
                    </TabText>
                  </TabLogoContainer>
                }
              >
                <TabContent>
                  {client.videoSrc && !client.imgSrc && (
                    <iframe
                      src={client.videoSrc}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      className="video"
                      allow="fullscreen; picture-in-picture"
                      allowFullScreen
                      title={client.link}
                    ></iframe>
                  )}

                  {client?.imgSrc && (
                    <img
                      src={client.imgSrc}
                      alt={client.link}
                      className="image"
                    />
                  )}
                  {!client.videoSrc && !client.imgSrc && (
                    <SectionHeadline
                      style={{ fontSize: "30px", justifyContent: "center" }}
                    >
                      COMING SOON
                    </SectionHeadline>
                  )}
                  {((client.link && client.videoSrc) ||
                    (client.link && client.imgSrc)) && (
                    <TabButtonContainer>
                      <TabButton to={`/${client.link}`} replace>
                        SEE CASE STUDY
                      </TabButton>
                    </TabButtonContainer>
                  )}
                </TabContent>
              </TabPane>
            );
          })}
        </Tabs>
        <ArrowDownContainer>
          <ArrowDownButton href="#our-team" />
        </ArrowDownContainer>
      </Content>
    </Layout>
  );
};
