import React from "react";
import { Routes, Route } from "react-router-dom";
import CaseStudyPage from "./pages/CaseStudyPage";
import HomePage from "./pages/HomePage";
import ContactUs from "./pages/ContactUs";
import ApplicationPage from "./pages/ApplicationPage";
import "./index.css";

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:clientId" element={<CaseStudyPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/applications/:applicationId"
          element={<ApplicationPage />}
        />
      </Routes>
    </>
  );
};

export default App;
