import React, { useEffect, useState } from "react";
import logo from "../media/logo.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { SoMeButton } from "./SoMeButton";

interface NavigationBarProps {
  isVisible?: (b: boolean) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isVisible }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    isVisible && isVisible(visible);
  }, [visible, isVisible]);
  return (
    <NavigationContainer>
      <SpaceBetweenContainer>
        <Link to="/" onClick={() => setVisible(false)}>
          {!visible && <Logo src={logo} />}
        </Link>
        <FlexBox>
          <div
            onClick={() => {
              setVisible((s) => !s);
            }}
          >
            {visible ? (
              <CloseButton>Close</CloseButton>
            ) : (
              <BorderContainer>
                <TopHrElement className="element" />
                <BottomHrElement className="element" />
              </BorderContainer>
            )}
          </div>

          {visible && (
            <DrawerContainer
              autoFocus={false}
              className="drawerContainer"
              width="100%"
              height="100%"
              closable={false}
              placement="right"
              onClose={() => setVisible(false)}
              visible={visible}
            >
              <NavDrawerButtons>
                <ContactNav
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setVisible(false);
                    navigate("/");
                  }}
                >
                  <SmallNavNo>01.</SmallNavNo>Home
                </ContactNav>
                <ContactNav
                  onClick={() => {
                    setVisible(false);
                    navigate("/#about-us");
                    setTimeout(() => {
                      window.location.href = "#about-us";
                    }, 300);
                  }}
                >
                  <SmallNavNo>02.</SmallNavNo>About
                </ContactNav>
                <ContactNav
                  href="#our-work"
                  onClick={() => {
                    setVisible(false);
                    navigate("/#our-work");
                    setTimeout(() => {
                      window.location.href = "#our-work";
                    }, 300);
                  }}
                >
                  <SmallNavNo>03.</SmallNavNo>Our clients
                </ContactNav>
                <ContactNav
                  onClick={() => {
                    setVisible(false);
                    navigate("/#our-team");
                    setTimeout(() => {
                      window.location.href = "#our-team";
                    }, 300);
                  }}
                >
                  <SmallNavNo>04.</SmallNavNo>The team
                </ContactNav>
                <ContactNav
                  onClick={() => {
                    setVisible(false);
                    navigate("/contact-us");
                  }}
                >
                  <SmallNavNo>05.</SmallNavNo>Contact
                </ContactNav>
              </NavDrawerButtons>
              <SoMeContainer>
                <SoMeButton
                  title="Instagram"
                  href="https://www.instagram.com/thisistwentyagency/"
                  className="soMeButtonsContactUs"
                />
                <SoMeButton
                  title="LinkedIn"
                  href="https://www.linkedin.com/company/thisistwentyagency/"
                  className="soMeButtonsContactUs"
                />
                <SoMeButton
                  title="Facebook"
                  href="https://www.facebook.com/thisistwentyagency"
                  className="soMeButtonsContactUs"
                />
              </SoMeContainer>
            </DrawerContainer>
          )}
        </FlexBox>
      </SpaceBetweenContainer>
    </NavigationContainer>
  );
};

export default NavigationBar;

const NavigationContainer = styled.div`
  width: 100%;
  height: 120px;
  max-width: 1920px;
  margin: 0 auto;
  background-color: transparent;
  z-index: 1000;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 90px;
  }
`;

const SoMeContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 4%;
  right: 0;
  margin-right: 4%;
`;

const FlexBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 3rem;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const TopHrElement = styled.div`
  border-bottom: 3px solid var(--black);
  width: 45px;
  margin-right: 2rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 30px;
    border-bottom: 2px solid var(--black);
    margin-right: 0rem;
  }
`;

const BottomHrElement = styled.div`
  border-bottom: 3px solid var(--black);
  padding-top: 8px;
  width: 25px;
  margin-right: 2rem;

  @media screen and (max-width: 768px) {
    width: 15px;
    border-bottom: 2px solid var(--black);
    margin-right: 1rem;
  }
`;

const BorderContainer = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  .element {
    transition: 0.3s ease color;
  }
  &:hover {
    .element {
      border-bottom: 3px solid var(--blue);
    }
  }
`;

const CloseButton = styled.div`
  font-family: var(--rustica);
  font-size: 19px;
  margin-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s ease color;

  &:hover {
    color: var(--blue);
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
    margin-right: 1rem;
  }
`;

export const Logo = styled.img`
  height: 100%;
  width: 10rem;
  object-fit: cover;
  margin-left: 2rem;

  @media screen and (max-width: 768px) {
    width: 6rem;
    margin-left: .5rem;
  }
`;

const DrawerContainer = styled(Drawer)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1000;
  border: none;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const NavDrawerButtons = styled.div`
  margin: auto;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  margin-left: 12rem;
  max-width: 1920px;

  @media screen and (max-width: 768px) {
    margin-left: 3rem;
  }
`;

export const ContactNav = styled.a`
  margin-bottom: 12px;
  font-family: var(--shippori);
  font-size: 70px;
  text-decoration: none;
  color: var(--black);
  transition: 0.3s ease color;
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover, &:active {
    color: var(--blue);

    span {
      transition: 0.3s ease color;
      color: var(--orange);
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 37px;
    margin-bottom: 2rem;
  }
`;

export const SmallNavNo = styled.span`
  font-family: var(--rustica);
  font-size: 18px;
  line-height: 2;
  letter-spacing: 1px;
  padding-right: 12px;
  padding-top: 8px;
  color: var(--blue);

  @media screen and (max-width: 768px) {
    font-size: 13px;
    padding-top: 0;
    padding-bottom: 8px;
  }
`;
