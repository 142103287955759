import React from "react";
import { Layout } from "antd";
import {
  Headline,
  Grid,
  Principles,
  PrincipleItem,
  PrincipleTitle,
  PrincipleText,
  ArrowDownContainer,
} from "./AboutUsStyles";

import { ArrowDownButton } from "../../components/ArrowDownButton";

const { Content } = Layout;

interface Principle {
  title: string;
  text: string;
}

export interface AboutUsProps {
  headline: string;
  principles: Principle[];
}

export const AboutUs: React.FC<AboutUsProps> = ({ headline, principles }) => {
  return (
    <Layout id="about-us" className="layout">
      <Content className="content relative">
        <Grid>
          <div>
            <Headline>{headline}</Headline>
          </div>
          <Principles>
            {principles.map((principle, index) => (
              <PrincipleItem key={index}>
                <PrincipleTitle>{principle.title}</PrincipleTitle>
                <PrincipleText>{principle.text}</PrincipleText>
              </PrincipleItem>
            ))}
          </Principles>
        </Grid>
        <ArrowDownContainer>
          <ArrowDownButton href="#our-work" />
        </ArrowDownContainer>
      </Content>
    </Layout>
  );
};
