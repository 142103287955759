import React from "react";
import { ArrowDownContainer } from "../AboutUs/AboutUsStyles";
import { ArrowDownButton } from "../../components/ArrowDownButton";
import { Layout } from "antd";
import { SectionName } from "../../components/SectionName";
import {
  Paragraph,
  Grid,
  CardMember,
  Name,
  Picture,
  ParagraphTitle,
} from "./OurTeamStyles";

const { Content } = Layout;

interface Member {
  name: string;
  position: string;
  picture: string;
}

export interface OurTeamProps {
  description1stParagraph: string;
  members: Member[];
}

export const OurTeam: React.FC<OurTeamProps> = ({
  description1stParagraph,
  members,
}) => {
  return (
    <Layout id="our-team" className="layout layoutOurTeam">
      <Content className="content contentSection relative">
        <SectionName name="Meet our team" />

        <ParagraphTitle>{description1stParagraph}</ParagraphTitle>

        <Grid>
          {members.map((member, index) => {
            return (
              <CardMember key={index}>
                <Name>{member.name}</Name>
                <Paragraph
                  style={{
                    paddingTop: "0",
                  }}
                >
                  {member.position}
                </Paragraph>
                <Picture src={member.picture} alt="memberPicture" />
              </CardMember>
            );
          })}
        </Grid>

        <ArrowDownContainer>
          <ArrowDownButton href="#contact-us" />
        </ArrowDownContainer>
      </Content>
    </Layout>
  );
};
