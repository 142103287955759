import React, { useEffect, useState, useCallback } from "react";
import Layout from "antd/lib/layout/layout";
import CaseDescription, {
  Case,
} from "../sections/CaseDescription/CaseDescription";
import { Contact } from "../sections/Contact/Contact";
import MovingShapes from "../components/MovingShapes";
import NavigationBar from "../components/NavigationBar";
import { useParams } from "react-router-dom";

const CaseStudyPage: React.FC = () => {
  const { clientId } = useParams();
  const [cases, setCases] = useState<Case[]>();
  const [clients, setClients] = useState<{ id: string; name: string }[]>([]);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [officeAddress, setOfficeAddress] = useState<string>("");

  const showOurClients = useCallback(
    (data: any) => {
      let clientsArr: { id: string; name: string }[] = [];

      const filteredCases = data.filter(
        (caseStudy: any) => caseStudy.acf.clientId === clientId
      );

      const caseStudiesWithData = data.filter(
        (c: any) => c.acf.videoSrc || c.acf.imgSrc
      );

      caseStudiesWithData.map((client: any) =>
        clientsArr.push({ id: client.acf.clientId, name: client.acf.name })
      );

      setClients(clientsArr.reverse());
      setCases(filteredCases[0]?.acf.caseStudy);
    },
    [clientId]
  );

  const showContactUs = (data: any) => {
    console.log(data);
    return setOfficeAddress(data[0].acf.office_address);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const endpointOurClients =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/our_clients?per_page=100";
    const endpointContactus =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/contact_us";
    fetch(endpointOurClients)
      .then((e) => e.json())
      .then(showOurClients);

    fetch(endpointContactus)
      .then((e) => e.json())
      .then(showContactUs);
  }, [clientId, showOurClients]);

  const getDrawerVisibility = (e: boolean) => setVisibleDrawer(e);
  return (
    <>
      <MovingShapes />
      {cases && (
        <Layout style={{ zIndex: "100" }}>
          <NavigationBar isVisible={(e) => getDrawerVisibility(e)} />
          {!visibleDrawer && (
            <>
              <CaseDescription
                cases={cases}
                clients={clients}
                clientId={clientId}
              />

              <Contact contactAddress={officeAddress} arrowUpId="#top" />
            </>
          )}
        </Layout>
      )}
    </>
  );
};

export default CaseStudyPage;
