import React, { useEffect, useState } from "react";
import MovingShapes from "../components/MovingShapes";
import NavigationBar from "../components/NavigationBar";
import styled from "styled-components";
import { Layout } from "antd";
import { SoMeButton } from "../components/SoMeButton";

type Contacts = {
  email: string;
  position: string;
  name: string;
  phone_no: string;
};

const ContactUs: React.FC = () => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const getDrawerVisibility = (e: boolean) => setVisibleDrawer(e);
  const [officeAddress, setOfficeAddress] = useState<string>("");
  const [contacts, setContacts] = useState<Contacts[]>([]);

  useEffect(() => {
    const endpointContactus =
      "https://thisistwentyagencydotcom.wpcomstaging.com/wp-json/acf/v3/contact_us";

    fetch(endpointContactus)
      .then((e) => e.json())
      .then(showContactUs);
  }, []);

  const showContactUs = (data: any) => {
    setContacts(data[0].acf.contacts);
    return setOfficeAddress(data[0].acf.office_address);
  };

  return (
    <>
      <MovingShapes />
      <Layout>
        <NavigationBar isVisible={(e) => getDrawerVisibility(e)} />

        {!visibleDrawer && (
          <Container>
            <SoMeContainer>
              <SoMeButton
                title="Instagram"
                href="https://www.instagram.com/thisistwentyagency/"
                className="soMeButtonsContactUs"
              />
              <SoMeButton
                title="LinkedIn"
                href="https://www.linkedin.com/company/thisistwentyagency/"
                className="soMeButtonsContactUs"
              />
              <SoMeButton
                title="Facebook"
                href="https://www.facebook.com/thisistwentyagency"
                className="soMeButtonsContactUs"
              />
            </SoMeContainer>
            <TextContainer>
              <Headline>CONTACT</Headline>
              <ParagraphContainer>
                <Paragraph>
                  Feel free to reach out if you want to collaborate with us, or
                  simply have a chat.
                </Paragraph>
                <ContactsContainer>
                  {contacts?.map((contact, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1rem",
                      }}
                    >
                      <Paragraph
                        style={{
                          marginRight: "0",
                          fontWeight: "bold",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                      >
                        {contact?.position}
                      </Paragraph>
                      <Paragraph>{contact?.name}</Paragraph>
                      <StyledAnchor href={`mailto:${contact?.email}`}>
                        <HigherThanMobileEmail>
                          Email: {contact?.email}
                        </HigherThanMobileEmail>
                        <MobileEmail>{contact.email}</MobileEmail>
                      </StyledAnchor>
                      <StyledAnchor href={`tel:${contact?.phone_no}`}>
                        Phone: {contact?.phone_no}
                      </StyledAnchor>
                    </div>
                  ))}
                </ContactsContainer>
              </ParagraphContainer>
              <ParagraphContainer>
                <Paragraph>Have a coffee with us at our office.</Paragraph>

                <StyledAnchor
                  href="https://www.google.com/maps/place/Blegdamsvej+6,+2200+K%C3%B8benhavn/@55.6908624,12.5604858,17z/data=!3m1!4b1!4m5!3m4!1s0x46525300f757e521:0x2f51ff3cb51d60b5!8m2!3d55.6908624!4d12.5626798"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ lineHeight: "1.5" }}
                >
                  {officeAddress.split(", ")[0]}
                  <br /> {officeAddress.split(",")[1]}
                </StyledAnchor>
              </ParagraphContainer>
            </TextContainer>
          </Container>
        )}
      </Layout>
    </>
  );
};

export default ContactUs;

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 16rem;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const SoMeContainer = styled.div`
  position: absolute;
  left: 0;
  margin-left: 4%;
  bottom: 0;
  margin-bottom: 4%;

  @media screen and (max-width: 768px) {
    margin-bottom: 15%;
  }
`;

const HigherThanMobileEmail = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileEmail = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    text-decoration: none;
    color: var(--black);
    font-family: var(--rustica);
    font-style: normal;
    white-space: nowrap;
    letter-spacing: 1px;
    font-size: 10px;
    transition: 0.3s ease color;
  }
`;

const Container = styled.div`
  display: flex;
  margin: auto;
  justify-content: flex-end;
  padding-top: 6rem;
  max-width: 1920px;
  width: 100vw;
  height: calc(100vh - 120px);
  position: relative;
  @media screen and (max-width: 768px) {
    padding-top: 3rem;
    height: calc(100vh - 60px);
  }
`;

const TextContainer = styled.div`
  width: 55%;
  height: 65%;

  @media screen and (max-width: 1240px) {
    width: 67%;
    height: 85%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Headline = styled.div`
  margin-bottom: 3rem;
  font-size: 62px;
  font-family: var(--shippori);
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
`;

const ParagraphContainer = styled.div`
  border-bottom: 1px solid var(--blue);
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  margin-right: 7rem;
  display: flex;

  @media screen and (max-width: 1240px) {
    margin-right: 4rem;
  }

  @media screen and (max-width: 768px) {
    margin-right: 2.5rem;
  }
`;

const Paragraph = styled.div`
  font-size: 15px;
  font-family: var(--rustica);
  width: 240px;
  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: 200;
  margin-right: 2rem;

  @media screen and (max-width: 1240px) {
    margin-right: 10%;
    width: 180px;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 130px;
    margin-right: 14%;
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: var(--black);
  font-family: var(--rustica);
  font-style: normal;
  white-space: nowrap;
  letter-spacing: 1px;
  font-size: 15px;
  transition: 0.3s ease color;

  &:hover {
    color: var(--blue);
  }

  @media screen and (max-width: 1240px) {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
