import React from "react";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Column,
  Title,
  ContainerContactUs,
  ArrowUpContainer,
  SoMeContact,
  SoMeContainer,
  NavContainer,
  ContactNav,
  SmallNavNo,
  SectionName,
  Line,
} from "./ContactStyles";
import { ArrowDownButton } from "../../components/ArrowDownButton";

const { Content } = Layout;

interface ContactProps {
  arrowUpId?: string;
  contactAddress: string;
}

export const Contact: React.FC<ContactProps> = ({
  arrowUpId,
  contactAddress,
}) => {
  const navigate = useNavigate();
  return (
    <Layout id="contact-us" className="footerLayout">
      <Content className="content contentFooter relative ">
        <Grid>
          <Column>
            <Title>Office</Title>
            <div style={{ marginTop: "1rem" }}>
              <SoMeContact
                href="https://www.google.com/maps/place/Blegdamsvej+6,+2200+K%C3%B8benhavn/@55.6908624,12.5604858,17z/data=!3m1!4b1!4m5!3m4!1s0x46525300f757e521:0x2f51ff3cb51d60b5!8m2!3d55.6908624!4d12.5626798"
                target="_blank"
                rel="noreferrer noopener"
              >
                {contactAddress}
              </SoMeContact>
              <ContainerContactUs>
                <SectionName href="mailto:thomas@thisistwentyagency.com">
                  Get in touch <Line />
                </SectionName>
              </ContainerContactUs>
            </div>
          </Column>

          <Column>
            <Title>Follow us</Title>
            <SoMeContainer>
              <SoMeContact
                href="https://www.instagram.com/thisistwentyagency/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Instagram
              </SoMeContact>
              <SoMeContact
                href="https://www.linkedin.com/company/thisistwentyagency/"
                target="_blank"
                rel="noreferrer noopener"
              >
                LinkedIn
              </SoMeContact>
              <SoMeContact
                href="https://www.facebook.com/thisistwentyagency"
                target="_blank"
                rel="noreferrer noopener"
              >
                Facebook
              </SoMeContact>
            </SoMeContainer>
          </Column>
          <Column>
            <NavContainer>
              <ContactNav
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/");
                }}
              >
                <SmallNavNo>01</SmallNavNo>Home
              </ContactNav>
              <ContactNav
                href="#about-us"
                onClick={() => {
                  navigate("/#about-us");
                }}
              >
                <SmallNavNo>02</SmallNavNo>About
              </ContactNav>
              <ContactNav
                href="#our-work"
                onClick={() => {
                  navigate("/#our-work");
                }}
              >
                <SmallNavNo>03</SmallNavNo>Our clients
              </ContactNav>
              <ContactNav
                href="#our-team"
                onClick={() => {
                  navigate("/#our-team");
                }}
              >
                <SmallNavNo>04</SmallNavNo>The team
              </ContactNav>
              <ContactNav
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                <SmallNavNo>05</SmallNavNo>Contact
              </ContactNav>
            </NavContainer>
          </Column>
        </Grid>
        <ArrowUpContainer>
          <ArrowDownButton onClick={() => window.scrollTo(0, 0)} arrowUp />
        </ArrowUpContainer>
      </Content>
    </Layout>
  );
};
