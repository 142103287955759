import styled from "styled-components";

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const ContainerVideo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin: 2.5rem auto 0;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
    padding: 0 1rem;
  }
`;

export const Left = styled.div`
  height: 0.6rem;
  width: 0.6rem;
  margin-right: 8px;
  margin-top: 5px;
  border-bottom: 2px solid var(--blue);
  border-left: 2px solid var(--blue);
  transform: rotate(45deg);

  @media screen and (max-width: 768px) {
    margin-right: 2px;
    height: 0.4rem;
    width: 0.4rem;
    border-bottom: 1px solid var(--blue);
    border-left: 1px solid var(--blue);
    margin-top: 3px;
  }
`;

export const Right = styled.div`
  height: 0.6rem;
  width: 0.6rem;
  margin-left: 8px;
  margin-top: 5px;
  border-bottom: 2px solid var(--blue);
  border-left: 2px solid var(--blue);
  transform: rotate(-135deg);

  @media screen and (max-width: 768px) {
    margin-left: 2px;
    height: 0.4rem;
    width: 0.4rem;
    border-bottom: 1px solid var(--blue);
    border-left: 1px solid var(--blue);
    margin-top: 3px;
  }
`;

export const CaseButton = styled.button`
  all: unset;
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
  text-transform: lowercase;
  color: var(--black);
  font-family: var(--rustica);
  margin-bottom: 0.8rem;

  &:hover {
    opacity: 0.6;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
    max-width: 170px;
    white-space: normal;
  }
`;

export const Shiporri = styled.p`
  font-family: var(--shippori);
  font-size: 16px;
  margin-right: 4px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Rustica = styled.p`
  font-family: var(--rustica);
  font-size: 12px;
  line-height: 2;
  letter-spacing: 1px;
`;

export const Headline = styled.h2`
  font-family: var(--shippori);
  font-size: 40px;
  font-weight: bold;
  color: var(--blue);
  text-transform: capitalize;
  margin-bottom: 2rem;
  margin-top: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

export const CaseVideoContainer = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 960px) {
    margin-top: 2rem;
  }
`;

export const ClientsNavigation = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 6%;
  margin-top: 10%;

  @media screen and (max-width: 1380px) {
    flex-direction: row;
    margin-top: -10px;
    column-gap: 1rem;
  }

  @media screen and (max-width: 960px) {
    margin-top: -35px;
    padding-right: 1rem;
    flex-wrap: wrap;
  }
`;

export const CaseImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CaseVideo = styled.div`
  display: flex;
  width: 60rem;
  height: 35rem;
  aspect-ratio: 16/9;
  margin: 1rem auto 1rem auto;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
    object-fit: cover;

    .videoClient {
      height: 100%;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4.5rem;
  margin-top: 4rem;

  @media screen and (max-width: 1240px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-top: 2rem;
  }
`;

export const Title = styled.h3`
  font-family: var(--shippori);
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const Text = styled.p`
  font-family: var(--rustica);
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.5px;
  padding-top: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: justify;
    font-size: 10px;
    font-weight: 100;
  }
`;

export const CampaignImageContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5rem;
  @media screen and (max-width: 1024px) {
    gap: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const CampaignLeft = styled.div`
  height: 1rem;
  width: 1rem;
  margin-top: 17rem;
  margin-right: 1rem;
  border-bottom: 2px solid var(--blue);
  border-left: 2px solid var(--blue);
  transform: rotate(45deg);
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin-top: 15rem;
    margin-right: 0rem;
  }
`;

export const CampaignRight = styled.div`
  height: 1rem;
  width: 1rem;
  margin-top: 17rem;
  margin-left: 1rem;
  border-bottom: 2px solid var(--blue);
  border-left: 2px solid var(--blue);
  transform: rotate(-135deg);
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin-top: 15rem;
    margin-left: 0;
  }
`;

export const MockupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IFrameContainer = styled.div`
  margin: 4rem auto 0;
  min-width: 45rem;
  height: 30rem;

  @media screen and (max-width: 1024px) {
    min-width: 35rem;
    height: 30rem;
  }

  @media screen and (max-width: 768px) {
    min-width: 18rem;
    height: 12rem;
    margin-top: 4rem;
  }
`;

export const Mockup = styled.img`
  margin: 4rem auto 0;
  min-width: 25rem;
  max-width: 37rem;
  height: 30rem;
  object-fit: contain;

  @media screen and (max-width: 1024px) {
    width: 35rem;
  }

  @media screen and (max-width: 768px) {
    min-width: 17rem;
    width: 17rem;
    height: 22rem;
    margin-top: 4rem;
  }
`;

export const MockupImageIndicator = styled.div`
  width: 3rem;
  margin: 2rem 0.5rem;
  border-width: 2px;
  border-style: solid;

  @media screen and (max-width: 768px) {
    width: 2rem;
    margin: 1rem 0.5rem;
    border-width: 1px;
  }
`;

export const IphoneImageContainer = styled.img`
  margin-top: 3rem;
  margin-bottom: 4rem;
  width: 100%;
  height: 55vh;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
    height: 35vh;
  }
`;

export const PresentationImage = styled.img`
  margin-top: 3rem;
  margin-bottom: 4rem;
  width: 100%;
  height: 90vh;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    object-fit: contain;
    margin-bottom: 1rem;
    margin-top: 1rem;
    height: auto;
  }
`;

export const AwardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 10%;
  width: 70%;
  /* style={{ margin: "6rem auto" }} */
  margin: 6rem auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 1rem auto;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 5%;
    width: 100%;
    gap: 0;
  }
`;

export const PublicationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 10%;
  margin-top: 2rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 5%;
  }
`;

export const ContainerAward = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  width: 25%;
  height: auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 33%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
    padding: 15px;
  }
`;

export const ContainerPublication = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  width: 33%;
  height: auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    width: 250px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 15px;
    p {
      width: auto;
    }
  }
`;

export const Logo = styled.img`
  height: auto;
  width: 10rem;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    width: 8rem;
  }
`;

export const CampaingResultsContainer = styled.div`
  margin-top: 12rem;

  @media screen and (max-width: 768px) {
    margin-top: 4rem;
  }
`;

export const CampaignResults = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    gap: 0;
  }
`;

export const CampaignResult = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  width: 10rem;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 6rem;
  }
`;
