import React from "react";
import styled from "styled-components";

const StyledAnchor = styled.a`
  text-decoration: none;
  color: var(--black);
  font-family: var(--rustica);
  font-style: normal;
  font-weight: 200;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  transition: 0.3s ease color;

  &:hover {
    color: var(--blue);
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

interface SoMeButtonProps {
  title: string;
  href: string;
  className?: string;
}

export const SoMeButton: React.FC<SoMeButtonProps> = ({
  title,
  href,
  className,
}) => {
  return (
    <StyledAnchor
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {title}
    </StyledAnchor>
  );
};
